import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[appShowHidePassword]',
})
export class ShowHidePasswordDirective {
	private isShown = false;

	constructor(private el: ElementRef) {
		this.setup();
	}

	toggle(span: HTMLElement) {
		this.isShown = !this.isShown;
		if (this.isShown) {
			this.el.nativeElement.querySelector('input').setAttribute('type', 'text');
			span.classList.remove('fa-eye');
			span.classList.add('fa-eye-slash');
		} else {
			this.el.nativeElement.querySelector('input').setAttribute('type', 'password');
			span.classList.add('fa-eye');
			span.classList.remove('fa-eye-slash');
		}
	}

	setup() {
		const parent = this.el.nativeElement.parentNode;
		const div = document.createElement('div');
		const span = document.createElement('span');
		parent.setAttribute('class', 'k-hstack');
		span.setAttribute('class', 'password-toggle fal fa-eye');
		var ngAttribute = parent.getAttributeNames()[0];
		span.setAttribute(ngAttribute, '');
		div.onclick = (event) => {
			this.toggle(span);
		};
		div.appendChild(span);
		parent.appendChild(div);
	}
}
