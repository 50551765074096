import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormBuilder,
	FormGroup,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from '@angular/forms';

import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { EhsRoles } from '@app/core/services/legacy-role-mapper.service';
import { ProductLine } from '@app/shared/models/ProductLineEnum';
import { EmployeePickerItemDto } from '@app/shared/models/employee-picker-item-dto';
import { EmployeeService } from '@app/employee/employee/shared/services/employee.service';
import { EmployeePickerSearchDto } from '@app/shared/models/employee-picker-search-dto';

@Component({
	selector: 'kpa-employee-picker',
	templateUrl: './employee-picker.component.html',
	styleUrls: ['./employee-picker.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EmployeePickerComponent),
			multi: true,
		},
	],
})
export class EmployeePickerComponent implements ControlValueAccessor, OnInit {
	@Output() change = new EventEmitter<string | string[] | EmployeePickerItemDto | EmployeePickerItemDto[]>();
	@Input() isMultipicker: boolean = true;
	@Input() maxSelectableUsers: number = 50;
	@Input() selectedSsoUserIds: string | string[] = [];
	@Input() clientUniversalId: string;
	@Input() serviceToUniversalIdFilter: string;
	@Input() ehsRoleFilter: EhsRoles;
	@Input() ehsRoleExcludeFilter: EhsRoles;
	//only EHS & HR are currently wired up
	@Input() productLineFilter: ProductLine;
	@Input() dialogTitle: string = 'Select Employee';
	@Input() buttonTitle: string = 'Select Employee';
	@Input() disabled: boolean = false;
	@Input() readonly: boolean = false;
	@Input() showButton: boolean = true;

	private _value: string | string[] | EmployeePickerItemDto | EmployeePickerItemDto[];

	@Input()
	set value(val: string | string[] | EmployeePickerItemDto | EmployeePickerItemDto[]) {
		this._value = val;
		this.writeValue(this._value);
	}

	get value(): string | string[] | EmployeePickerItemDto | EmployeePickerItemDto[] {
		return this._value;
	}
	@Output() valueChange = new EventEmitter<string | string[] | EmployeePickerItemDto | EmployeePickerItemDto[]>();

	loadedEmployees: EmployeePickerItemDto[] = [];
	selectedEmployees: EmployeePickerItemDto[] = [];
	selectionLoading: boolean = false;
	searching: boolean = false;
	nameFilter: string;
	nameFilterChanged$: Subject<string> = new Subject<string>();
	employeePage: number = 0;

	public isDisabled: boolean = false;

	public pickerOpened = false;

	public closePicker(): void {
		this.pickerOpened = false;
	}

	public openPicker(): void {
		//resetting name filter on open
		this.nameFilterChanged$.next('');
		if (this.selectedSsoUserIds?.length > 0) {
			this.setValueFromIds(this.selectedSsoUserIds);
		}
		this.pickerOpened = true;
	}

	private onChange: (value: string | string[] | EmployeePickerItemDto[] | EmployeePickerItemDto) => void;
	private onTouched: () => void;

	constructor(private employeeService: EmployeeService) {}

	ngOnInit(): void {
		if (this.isMultipicker == true) {
			this.dialogTitle = this.dialogTitle.startsWith('Select Employee') ? 'Select Employees' : this.dialogTitle;
			this.buttonTitle = this.buttonTitle.startsWith('Select Employee') ? 'Select Employees' : this.buttonTitle;
		}

		this.isDisabled = this.disabled;

		this.nameFilterChanged$
			.pipe(
				debounceTime(500), // 500ms delay after user stops typing
				distinctUntilChanged(),
				switchMap((value) => {
					this.nameFilter = value;
					this.loadedEmployees = [];
					this.employeePage = 0;
					return this.fetchEmployeesObservable(); // Returns an observable
				})
			)
			.subscribe(
				(result) => {
					this.searching = false;
					this.loadedEmployees = [...result];
				},
				(error) => {
					this.searching = false;
					console.error('Error fetching employees', error);
				}
			);
	}

	public setValueFromIds(value: string | string[]) {
		if (value) {
			const ssoUserIds: string[] = Array.isArray(value) ? value.slice(0, this.maxSelectableUsers) : [value];
			if (ssoUserIds.length > 0) {
				this.selectionLoading = true;
				this.employeeService.getEmployeePickerDataBySsoUserIds(ssoUserIds).subscribe((employees) => {
					this.selectedEmployees = [...employees];
					this.employeesUpdated();
					this.selectionLoading = false;
				});
			} else {
				this.selectedEmployees = [...[]];
				this.employeesUpdated();
			}
		}
	}

	pickerValueType: PickerValueType = null;
	public writeValue(value?: string | string[] | EmployeePickerItemDto[] | EmployeePickerItemDto): void {
		if (value) {
			if (this.pickerValueType == null) {
				if (Array.isArray(value) && (value as []).every((item) => typeof item === 'string')) {
					this.pickerValueType = PickerValueType.SsoUserIdArray;
				} else if (Array.isArray(value) && value.some((item) => typeof item === 'string') == false) {
					this.pickerValueType = PickerValueType.UserArray;
				} else if (typeof value === 'string') {
					this.pickerValueType = PickerValueType.SsoUserId;
				} else {
					this.pickerValueType = PickerValueType.User;
				}
			}

			switch (this.pickerValueType) {
				case PickerValueType.SsoUserId:
				case PickerValueType.SsoUserIdArray:
					this.setValueFromIds(value as string | string[]);
					break;

				case PickerValueType.User:
					this.selectedEmployees = [value] as EmployeePickerItemDto[];
					break;
				case PickerValueType.UserArray:
					this.selectedEmployees = value as EmployeePickerItemDto[];
					break;
			}
		}
	}

	get isMaxLimitReached(): boolean {
		if (this.isMultipicker) {
			return this.selectedEmployees.length >= this.maxSelectableUsers;
		} else {
			return false;
		}
	}

	public registerOnChange(fn: (value?: EmployeePickerItemDto[] | EmployeePickerItemDto) => void): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public onBlur(): void {
		this.onTouched();
	}

	nameFilterChanged(value: string): void {
		this.nameFilterChanged$.next(value);
	}

	public fetchEmployeesObservable(): Observable<EmployeePickerItemDto[]> {
		this.searching = true;
		const filters: EmployeePickerSearchDto = this.getFilters();

		// Return the observable from employeeService, allowing it to be canceled by switchMap
		return this.employeeService.getEmployeePickerData(this.clientUniversalId, filters);
	}

	public fetchEmployees() {
		return this.fetchEmployeesObservable().subscribe(
			(result) => {
				this.searching = false;
				this.loadedEmployees = [...this.loadedEmployees, ...result];
			},
			(error) => {
				this.searching = false;
				console.error('Error fetching employees', error);
			}
		);
	}

	public getFilters(): EmployeePickerSearchDto {
		const employeesPerPage: number = 100;
		return {
			nameFilter: this.nameFilter,
			ehsRoleFilter: this.ehsRoleFilter,
			ehsRoleExcludeFilter: this.ehsRoleExcludeFilter,
			productLineFilter: this.productLineFilter,
			serviceToUniversalIdFilter: this.serviceToUniversalIdFilter,
			skip: this.employeePage++ * employeesPerPage,
			take: employeesPerPage,
		};
	}

	selectEmployee(employee: EmployeePickerItemDto) {
		if (this.isMultipicker == false) {
			this.selectedEmployees = [employee];
			this.employeesUpdated();
		} else if (this.isMaxLimitReached == false) {
			this.selectedEmployees.push(employee);
			this.employeesUpdated();
		}
	}

	isEmployeeSelected(employee: EmployeePickerItemDto) {
		return this.selectedEmployees.findIndex((e) => e.ssoUserId == employee.ssoUserId) != -1;
	}

	removeEmployee(employee: EmployeePickerItemDto) {
		this.selectedEmployees = this.selectedEmployees.filter((e) => e.ssoUserId != employee.ssoUserId);
		this.employeesUpdated();
	}

	employeesUpdated() {
		this.selectedEmployees = [...this.selectedEmployees];
		if (this.pickerValueType == null && this.isMultipicker == true) {
			this.pickerValueType = PickerValueType.UserArray;
		}

		if (this.pickerValueType == null && this.isMultipicker == false) {
			this.pickerValueType = PickerValueType.User;
		}

		let updatedValue: string | string[] | EmployeePickerItemDto[] | EmployeePickerItemDto;
		let updatedFullValue: EmployeePickerItemDto[] | EmployeePickerItemDto;
		switch (this.pickerValueType) {
			case PickerValueType.SsoUserId:
				updatedValue = this.selectedEmployees[0].ssoUserId;
				updatedFullValue = this.selectedEmployees[0];
				if ((this.value ?? ('' as string)) != updatedValue) {
					this.valueChange.emit(updatedValue);
				}
				break;
			case PickerValueType.SsoUserIdArray:
				updatedValue = this.selectedEmployees.map((emp) => emp.ssoUserId);
				updatedFullValue = this.selectedEmployees;
				break;

			case PickerValueType.User:
				updatedValue = this.selectedEmployees[0];
				updatedFullValue = this.selectedEmployees[0];
				if ((this.value as EmployeePickerItemDto)?.ssoUserId != updatedValue.ssoUserId) {
					this.valueChange.emit(updatedValue);
				}
				break;
			case PickerValueType.UserArray:
				updatedValue = this.selectedEmployees;
				updatedFullValue = this.selectedEmployees;
				break;
		}

		if (this.onChange) {
			this.onChange(updatedValue);
		}

		this.change.emit(updatedFullValue);
		if (this.pickerValueType == PickerValueType.SsoUserId || this.pickerValueType == PickerValueType.User) {
			this.closePicker();
		}
	}
}

export enum PickerValueType {
	SsoUserId,
	SsoUserIdArray,
	User,
	UserArray,
}
