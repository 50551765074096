<div
	*ngIf="!hasOnlyOneLocation"
	class="location-picker">
	<kendo-multiselect
		kendoMultiSelectSummaryTag
		[data]="serviceTos"
		[valuePrimitive]="true"
		[checkboxes]="true"
		[autoClose]="false"
		[disabled]="disabled"
		[(ngModel)]="selectedServiceToValues"
		(valueChange)="onValueChange()"
		textField="serviceToName"
		valueField="serviceToUniversalId">
		<ng-template
			kendoMultiSelectHeaderTemplate
			*ngIf="addSelectAllOption">
			<span style="margin-left: 11px">
				<input
					type="checkbox"
					id="chk"
					kendoCheckBox
					[checked]="isChecked"
					[indeterminate]="isIndet"
					(click)="onClick()" />
				<kendo-label for="chk">{{ toggleAllText }}</kendo-label>
			</span>
		</ng-template>
		<ng-template
			kendoMultiSelectGroupTagTemplate
			let-dataItems>
			<ng-container *ngIf="dataItems.length > 1"> {{ dataItems.length }} Locations selected </ng-container>
			<ng-container *ngIf="dataItems.length === 1">
				{{ dataItems[0].serviceToName }}
			</ng-container>
		</ng-template>
	</kendo-multiselect>
</div>
