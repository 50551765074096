import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { EmployeeDto } from '../../shared/models/employeeDto';
import { ActionItemDto } from '@app/incidents/shared/models/actionItemDto';
import { LogService } from '@app/core/services/log.service';
import { Environment } from '../../../../../environments/environment';
import { UntypedFormGroup } from '@angular/forms';
import { BaseApiClient } from '@app/core/services/base-api-client.service';
import { UserProfileDataDto } from '../models/userProfileDataDto';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';
import { LookUpDisplayItem } from '@app/core/models/look-up-display-item';
import { TransferEmployeeDto } from '../models/transferEmployeeDto';
import { EmployeeOnboardingDto } from '../models/employeeOnboardingDto';
import { TransferEmployeePdfDto } from '@app/employee/employee/shared/models/transferEmployeePdfDto';
import { EmployeeInfoDto } from '../models/employeeInfoDto';
import { EmployeeLiteDto } from '../models/employeeLiteDto';
import { Guid } from '@app/shared/models/guid';
import { EmployeePickerSearchDto } from '../../../../shared/models/employee-picker-search-dto';
import { EmployeePickerItemDto } from '../../../../shared/models/employee-picker-item-dto';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
export interface IEmployeeService {
	url: string;
	get(id: string): Observable<EmployeeDto>;
	save(formGroup: UntypedFormGroup, modelDto?: EmployeeDto): Observable<{ id: string }>;
	getByUserId(id: string): Observable<EmployeeDto>;
	saveEmployeeDto(data: EmployeeDto): Observable<{ id: string }>;
	saveEmployeeInfoDto(data: EmployeeInfoDto): Observable<any>;
	getPotentialReportsToManagers(peopleId): Observable<any>;
	getPotentialReportsToManagersForLocation(serviceToId: string): Observable<LookUpDisplayItem<string>[]>;
	getOnboardingInfo(hrmPersonId: string): Observable<EmployeeOnboardingDto>;
	getActionItemsOfEmployee(employeeId: string): Observable<ActionItemDto[]>;
	getAssigneesForEmployee(ssoUserId: string): Observable<EmployeeDto[]>;
	getHrmPersonDetails(peopleId: string): Observable<any>;
}
@Injectable({
	providedIn: 'root',
})
export class EmployeeService extends BaseApiClient implements IEmployeeService {
	public baseUrl: string; // base url
	public url: string; // URL to web api
	public employeeHRMRoleId: any;

	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		private readonly loadingIndicatorService: LoadingIndicatorService,
		environment: Environment
	) {
		super();
		this.baseUrl = environment.services['employees'];
		this.url = this.baseUrl + 'employee';
	}

	/**
	 * Retrieves the entity
	 * @param id
	 */
	get(id: string): Observable<EmployeeDto> {
		const url = `${this.url}/${id}`;
		console.log('employee Get with id:' + id);

		this.loadingIndicatorService.startLoading();
		return this.http.get<EmployeeDto>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched employee id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * Retrieves the entity
	 * @param id
	 */
	getLite(id: string): Observable<EmployeeLiteDto> {
		const url = `${this.url}/${id}/lite`;
		console.log('employee Get Lite with id:' + id);

		this.loadingIndicatorService.startLoading();
		return this.http.get<EmployeeLiteDto>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched employee id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * Retrieves the entity
	 * @param userId
	 */
	getByUserId(userId: string): Observable<EmployeeDto> {
		const url = `${this.url}/GetByUserId/${userId}`;
		console.log('ServiceTo getByUserId with userId:' + userId);

		this.loadingIndicatorService.startLoading();
		return this.http.get<EmployeeDto>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched Employee userId=${userId}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * Updates or creates an entity
	 * @param entity
	 */
	save(formGroup: UntypedFormGroup, modelDto?: EmployeeDto): Observable<any> {
		let entity = formGroup.value;
		if (modelDto) {
			entity = {
				...entity,
				relationships: modelDto.relationships,
				roles: modelDto.roles,
				accessListExceptions: modelDto.accessListExceptions,
				ehsRole: modelDto.ehsRole,
				hrmRole: modelDto.hrmRole,
				employeePositions: modelDto.employeePositions,
			};
		}
		// let x = JSON.stringify(entity); // Leaving this in as it is useful for debugging form builder errors
		if (
			formGroup != null &&
			formGroup.get('primaryLocationServiceToId')?.value != null &&
			formGroup.get('primaryLocationServiceToId')?.value != ''
		) {
			entity.primaryLocationServiceToId = formGroup.get('primaryLocationServiceToId').value;
		}

		const errorHandler = this.createErrorResponseHandler(this.log, formGroup, (_) => this.loadingIndicatorService.stopLoading());

		// When adding a new employee we are manually setting the Id to the default value
		if (!entity.id || entity.id === Guid.empty) {
			this.loadingIndicatorService.startLoading();
			return this.http.post(this.url, entity, httpOptions).pipe(
				tap((id) => {
					this.log.logInformation(`created entity id=${id}`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(errorHandler)
			);
		} else {
			this.loadingIndicatorService.startLoading();
			return this.http.put<any>(this.url + '/' + entity.id, entity, httpOptions).pipe(
				tap((id) => {
					this.log.logInformation(`update entity id=${id}`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(errorHandler)
			);
		}
	}

	/**
	 * Activates an employee
	 * @param employeeId
	 */
	activateEmployee(employeeId: string): Observable<any> {
		const url = `${this.url}/${employeeId}/activate`;
		this.loadingIndicatorService.startLoading();
		return this.http.post<any>(url, undefined, httpOptions).pipe(
			tap((id) => {
				this.log.logInformation(`activated entity id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * Inactivates an employee
	 * Reassign action items to
	 * @param employeeId
	 * @param reassignEmployeeSsoUserId
	 */
	inactivateEmployee(employeeId: string, reassignEmployeeSsoUserId: string): Observable<any> {
		const url = `${this.url}/${employeeId}/inactivate`;
		this.loadingIndicatorService.startLoading();
		let entity = { ReassignEmployeeSsoUserId: reassignEmployeeSsoUserId };

		return this.http.post<any>(url, entity, httpOptions).pipe(
			tap((id) => {
				this.log.logInformation(`inactivated entity id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * get action items of employee
	 * @param ssoUserId
	 */
	getActionItemsOfEmployee(ssoUserId: string): Observable<ActionItemDto[]> {
		const url = `${this.url}/${ssoUserId}/GetActionItemsByUserId`;
		return this.http.get<ActionItemDto[]>(url).pipe(
			tap((): void => this.log.logInformation(`fetch action items`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	/**
	 * get assignees for employee
	 * @param ssoUserId
	 */
	getAssigneesForEmployee(ssoUserId: string): Observable<EmployeeDto[]> {
		const url = `${this.url}/${ssoUserId}/GetContactswithTenancy`;
		return this.http.get<EmployeeDto[]>(url).pipe(
			tap((): void => this.log.logInformation(`fetch assignees for employee`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	/**
	 * Retrieve users for a given ssoUserId, with a serviceToUniversalId, with a given list of role
	 * @param ssoUserId
	 */
	getContactsForServiceToAndRole(ssoUserId: string, ServiceToUniversalId: string, roleStrings: string[]): Observable<EmployeeDto[]> {
		const url = `${this.url}/GetContactsForServiceToAndRole`;
		let body = {
			ssoUserId: ssoUserId,
			ServiceToUniversalId: ServiceToUniversalId,
			roleStrings: roleStrings,
		};
		return this.http.post<EmployeeDto[]>(url, body).pipe(
			tap((): void => this.log.logInformation(`fetch employees`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	/**
	 * Reassign/Unassign action items of an employee
	 * @param employeeId
	 */
	reAssignActionItems(fromSsoUserId: string, toSsoUserId: string): Observable<ActionItemDto[]> {
		const url = `${this.url}/${fromSsoUserId}/ReassignActionItems`;
		return this.http.put<any>(url, toSsoUserId, httpOptions).pipe(
			tap((id) => {
				this.log.logInformation(`Reassign/Unassign action items=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	saveEmployeeDto(data: EmployeeDto): Observable<any> {
		this.loadingIndicatorService.startLoading();
		return this.http.put<any>(this.url + '/' + data.id, data, httpOptions).pipe(
			tap((id) => {
				this.log.logInformation(`update entity id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	saveEmployeeInfoDto(data: EmployeeInfoDto): Observable<any> {
		const endpoint = 'employeeInformation';

		this.loadingIndicatorService.startLoading();
		return this.http.put<any>(this.url + '/' + data.id + '/' + endpoint, data, httpOptions).pipe(
			tap((id) => {
				this.log.logInformation(`update entity id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	getUserProfileData(): Observable<UserProfileDataDto> {
		const url = `${this.url}/UserProfileData`;
		return this.http.get<UserProfileDataDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched UserProfileData`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getSSOUserProfileData(): Observable<UserProfileDataDto> {
		const url = `${this.url}/UserProfileData/SSOUserProfileData`;
		return this.http.get<UserProfileDataDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched SSOUserProfileData`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	isEmailUnique(email: string): Observable<any> {
		if (!email) {
			return of(true);
		}
		const emailEncoded = encodeURIComponent(email);
		const url = `${this.url}/Email/IsEmailUnique?email=${emailEncoded}`;
		return this.http.get(url).pipe(
			tap((): void => this.log.logInformation(`fetched ssoUserIds with email`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getUsersWithEmail(email: string): Observable<any> {
		const emailEncoded = encodeURIComponent(email);
		const url = `${this.url}/Email/${emailEncoded}/ssoUserIds`;
		return this.http.get(url).pipe(
			tap((): void => this.log.logInformation(`fetched ssoUserIds with email`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	updateUserProfileData(entity: UserProfileDataDto): Observable<any> {
		const url = `${this.url}/UserProfileData`;
		return this.http.put<UserProfileDataDto>(url, entity, httpOptions).pipe(
			tap((): void => this.log.logInformation(`updated UserProfileData`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	terminate(ssoUserId: string): Observable<any> {
		const url = `${this.url}/Terminate/` + ssoUserId;

		this.loadingIndicatorService.startLoading();
		return this.http.put<string>(url, httpOptions).pipe(
			tap((id) => {
				this.log.logInformation(`terminating entity id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * getPotentialReportsToManager retrieves the list of Potential
	 * Managers for the specified People ID.
	 * @param peopleId - the People ID of the user for whom to get Potential Managers
	 */
	public getPotentialReportsToManagers(peopleId: string): Observable<LookUpDisplayItem<string>[]> {
		// Start the Loading Service going
		this.loadingIndicatorService.startLoading();

		// Construct the URL to get the Potential Reports To Managers
		const url = `${this.url}/${peopleId}/PotentialReportsToManagers`;

		// Get the data from the HttpClient
		return this.http.get<LookUpDisplayItem<string>[]>(url).pipe(
			tap((): void => this.loadingIndicatorService.stopLoading()),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * Retrieve potential reports to managers for Location
	 */
	getPotentialReportsToManagersForLocation(serviceToId: string): Observable<LookUpDisplayItem<string>[]> {
		const url = `${this.baseUrl}ServiceTos/${serviceToId}/PotentialReportsToManagers`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<LookUpDisplayItem<string>[]>(url).pipe(
			tap((): void => {
				this.log.logVerbose(`fetched getPotentialReportsToManagersForLocation LookUpDisplayItem<string> for ${serviceToId}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * Transfer employee
	 */
	transferEmployee(formGroup: UntypedFormGroup, employeeOldHRMRoleId: any): Observable<any> {
		const url = `${this.url}/TransferEmployee`;
		this.loadingIndicatorService.startLoading();

		//raw value needed because of disabled serviceTo control
		const transferEmployeeDto = formGroup.getRawValue();
		//ensure a null DTO object is sent to the service instead of dto with null properties
		if (!transferEmployeeDto.hrRole.roleId) {
			transferEmployeeDto.hrRole = null;
		}
		if (!transferEmployeeDto.ehsRole.roleId) {
			transferEmployeeDto.ehsRole = null;
		}

		transferEmployeeDto.employeeOldHRMRoleId = employeeOldHRMRoleId;
		const errorHandler = this.createErrorResponseHandler(this.log, formGroup, (_) => this.loadingIndicatorService.stopLoading());
		return this.http.post(url, transferEmployeeDto, httpOptions).pipe(
			tap((id) => {
				this.log.logVerbose(`Transferred entity id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(errorHandler)
		);
	}

	getTransferEmployeePdf(entity: TransferEmployeePdfDto): Observable<any> {
		const url = `${this.url}/getTransferEmployeePdf`;
		return this.http
			.post(url, entity, {
				headers: httpOptions.headers,
				observe: 'response',
				reportProgress: false,
				responseType: 'blob',
			})
			.pipe(
				tap((id) => {
					this.log.logInformation(`retrieved transfer employee pdf`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(this.createErrorResponseHandler(this.log))
			);
	}

	/**
	 * Retrieves the entity
	 * @param hrmPersonId
	 */
	getOnboardingInfo(hrmPersonId: string): Observable<EmployeeOnboardingDto> {
		const url = `${this.url}/${hrmPersonId}/onboarding`;
		console.log('employee onboarding Get with hrm person id:' + hrmPersonId);

		this.loadingIndicatorService.startLoading();
		return this.http.get<EmployeeOnboardingDto>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched employee onboarding info id=${hrmPersonId}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	getHrmPersonDetails(peopleId: string): Observable<any> {
		const url = `${this.url}/${peopleId}/GetHrmPersonByPeopleId`;
		return this.http.get(url).pipe(
			tap((): void => {
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	public getEmployeePickerData(clientUniversalId, filters: EmployeePickerSearchDto): Observable<EmployeePickerItemDto[]> {
		const url = `${this.url}/GetEmployeePickerData/${clientUniversalId}`;

		return this.http.post<EmployeePickerItemDto[]>(url, filters);
	}

	public getEmployeePickerDataBySsoUserIds(ssoUserIds: string[]): Observable<EmployeePickerItemDto[]> {
		const url = `${this.url}/GetEmployeePickerDataBySsoUserIds`;

		return this.http.post<EmployeePickerItemDto[]>(url, ssoUserIds);
	}
}
