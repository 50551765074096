import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceToLiteDto } from '@app/clients/shared/models/ServiceToLiteDto';
import { ServiceToDto } from '@app/clients/shared/models/serviceToDto';
import { ServiceToService } from '@app/clients/shared/services/service-to.service';
import { ApplicationFeature } from '@app/core/models/application-feature-enums';
import { SecurityService } from '@app/core/services/security.service';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';

@Component({
	selector: 'kpa-location-multiselect-filter',
	templateUrl: './kpa-location-multiselect-filter.component.html',
	styleUrls: ['./kpa-location-multiselect-filter.component.css'],
})
export class KpaLocationMultiselectFilterComponent {
	@Output() selectedServiceToEvent = new EventEmitter<ServiceToLiteDto[]>();
	@Input() featureIdFilter: ApplicationFeature;
	@Input() disabled: boolean = false;
	@Input() addSelectAllOption: boolean = true;
	@Input() selectedServiceToValues: string[];
	isChecked: boolean;
	serviceTo: ServiceToLiteDto;
	serviceTos: ServiceToLiteDto[];
	hasOnlyOneLocation: boolean;
	primaryServiceToId: string;
	isInternalPage: boolean = false;

	constructor(
		private serviceToService: ServiceToService,
		private securityService: SecurityService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.isInternalPage = (this.router.url || '').includes('/clients/');
		let serviceToObservable: Observable<ServiceToDto[] | ServiceToDto>;
		const myUserInfoObservable = this.securityService.getMyUserInfo().pipe(take(1));

		if (this.isInternalPage == true) {
			//serviceTo observable query if internal page
			serviceToObservable = this.serviceToService.getByUniversalId(this.selectedServiceToValues[0]);
		} else {
			//serviceTo observable query if not internal page
			serviceToObservable = this.serviceToService.getServiceTo().pipe(take(1));
		}

		forkJoin({
			serviceTos: serviceToObservable,
			myUserInfo: myUserInfoObservable,
		}).subscribe((obvs) => {
			if (!Array.isArray(obvs.serviceTos)) {
				obvs.serviceTos = [obvs.serviceTos];
			}
			this.mapToServiceToLiteDto(obvs.serviceTos);
			this.hasOnlyOneLocation = this.serviceTos.length == 1;
			this.primaryServiceToId = obvs.myUserInfo?.primaryLocationServiceToId;
			this.setDefaultSelectedServiceTo();
		});
	}

	setDefaultSelectedServiceTo() {
		const routeServiceToUniversalId = (this.route.snapshot.params || {})['serviceToUniversalId'];
		const routeServiceTo = this.serviceTos.find((serviceTo) => serviceTo.serviceToUniversalId == routeServiceToUniversalId);
		const primaryServiceTo = this.serviceTos.find((serviceTo) => serviceTo.serviceToId == this.primaryServiceToId);

		if (this.hasOnlyOneLocation) {
			this.selectedServiceToValues = this.serviceTos.map((x) => x.serviceToUniversalId);;
		} else if (routeServiceTo) {
			this.selectedServiceToValues = [routeServiceTo.serviceToUniversalId];
		} else if (primaryServiceTo) {
			this.selectedServiceToValues = [primaryServiceTo.serviceToUniversalId];
		}
		
		const selectedServiceTos = this.serviceTos.filter((x) => this.selectedServiceToValues.includes(x.serviceToUniversalId));
		this.selectedServiceToEvent.emit(selectedServiceTos);

	}

	mapToServiceToLiteDto(serviceTos: ServiceToDto[]) {
		if (this.featureIdFilter) {
			serviceTos = serviceTos.filter((x) => x.locationAppFeatures.map((feature) => feature.featureId).includes(this.featureIdFilter));
		} else {
			serviceTos = serviceTos;
		}

		this.serviceTos = serviceTos.map((l) => {
			return {
				clientId: l.clientId,
				clientUniversalId: l.clientUniversalId,
				clientName: l.clientName,
				serviceToId: l.id,
				serviceToUniversalId: l.universalId,
				serviceToName: l.name,
			};
		});
	}

	public get isIndet() {
		return this.selectedServiceToValues.length !== 0 && this.selectedServiceToValues.length !== this.serviceTos.length;
	}

	public get toggleAllText() {
		return this.isChecked ? 'Deselect All' : 'Select All';
	}

	onClick() {
		this.isChecked = !this.isChecked;
		this.selectedServiceToValues = this.isChecked ? this.serviceTos.map((x) => x.serviceToUniversalId).slice() : [];
		const selectedServiceTos = this.serviceTos.filter((x) => this.selectedServiceToValues.includes(x.serviceToUniversalId));
		this.selectedServiceToEvent.emit(selectedServiceTos);
	}

	onValueChange() {
		this.isChecked = this.selectedServiceToValues.length === this.serviceTos.length;
		const selectedServiceTos = this.serviceTos.filter((x) => this.selectedServiceToValues.includes(x.serviceToUniversalId));
		this.selectedServiceToEvent.emit(selectedServiceTos);
	}
}
