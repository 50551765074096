import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutModule } from '@progress/kendo-angular-layout';

import { ValidationErrorsComponent } from './validation-errors/validation-errors.component';
import { OpenApiFormBuilder } from './open-api-form-builder.service';
import { ODataBindingDirective } from './odata-binding-directive';
import { BaseComponent } from '@app/shared/base-component';
import { KpaCardComponent } from './components/kpa-card/kpa-card.component';
import { KpaButtonGroupComponent } from './components/button-group/button-group.component';
import { KpaPrimaryButtonComponent } from './components/primary-button/primary-button.component';

// Angular Flex Layout CDK @6.0.0-beta.18 (Angular 6 Version)
import { FlexLayoutModule } from '@angular/flex-layout';

// Kendo UI Modules
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule, TextAreaModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DatePickerModule, DateInputModule, DateRangeModule } from '@progress/kendo-angular-dateinputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { KpaErrorCardComponent } from './components/kpa-error-card/kpa-error-card.component';
import { KpaStatusCardComponent } from './components/kpa-status-card/kpa-status-card.component';
import { KpaDropdownFilterComponent } from './components/kpa-dropdown-filter/kpa-dropdown-filter.component';
import { KpaDismissMessageComponent } from './components/kpa-dismiss-message/kpa-dismiss-message.component';
import { PhoneBoxComponent } from './components/phone-box/phone-box.component';
import { DialogModule, DialogsModule } from '@progress/kendo-angular-dialog';
import { KpaSpinnerComponent } from './components/kpa-spinner/kpa-spinner.component';
import { SsoLayoutComponent } from './components/sso-layout/sso-layout.component';
import { RouterModule } from '@angular/router';
import { KpaSuccessCardComponent } from './components/kpa-success-card/kpa-success-card.component';
import { KpaInsideSpinnerComponent } from './components/kpa-inside-spinner/kpa-inside-spinner.component';
import { KpaInfoCardComponent } from './components/kpa-info-card/kpa-info-card.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FileSelectModule } from '@progress/kendo-angular-upload';

import { FloatingDropDownlabelDirective } from './directives/floating-drop-downlabel.directive';
import { DisableAllDirective } from './directives/disable-all.directive';
import { FloatingDatePickerDirective } from './directives/floating-date-picker.directive';
import { FloatingTimePickerDirective } from './directives/floating-time-picker.directive';
import { IsEllipsisActiveDirective } from './directives/is-ellipsis-active.directive';
import { KpaSnackbarMessageComponent } from './components/kpa-snackbar-message/kpa-snackbar-message.component';
import { DropDownListFilterComponent } from './components/dropdown-filter/dropdownlist-filter.component';
import { ShowHidePasswordDirective } from './directives/show-hide-password.directive';
import { Autosize } from './directives/auto-resize.directive';
import { KpaTypeaheadDdlComponent } from './components/kpa-typeahead-ddl/kpa-typeahead-ddl.component';
import { ShimmerLoaderComponent } from './components/shimmer-loader/shimmer-loader.component';
import { KpaGridDirective } from './kendo/grid/directives/kpa-grid.directive';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { KpaButtonGroupDirective } from './kendo/buttons/directives/kpa-button-group.directive';
import { KpaDropDownButtonDirective } from './kendo/buttons/directives/kpa-dropdown-button.directive';
import { KpaSecondaryButtonDirective } from './kendo/buttons/directives/kpa-secondary-button.directive';
import { KpaTextButtonDirective } from './kendo/buttons/directives/kpa-text-button.directive';
import { KpaComboBoxDirective } from './kendo/dropdowns/directives/kpa-combobox.directive';
import { KpaDropDownListDirective } from './kendo/dropdowns/directives/kpa-dropdown-list.directive';
import { KpaMultiSelectDirective, KpaMultiSelectItemComponent } from './kendo/dropdowns/directives/kpa-multiselect.directive';
import { KpaRequiredDirective } from './kendo/shared/kpa-required.directive';
import { KpaThemeSampleComponent } from './kendo/sample/sample.component';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { KpaPrimaryButtonDirective } from './kendo/buttons/directives/kpa-primary-button.directive';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { KpaFileUploadComponent } from './components/kpa-file-upload/kpa-file-upload.component';
import { KpaFieldMappingComponent } from './components/kpa-field-mapping/kpa-field-mapping.component';
import { KpaShowHidePasswordDirective } from './kendo/inputs/directives/kpa-show-hide-password.directive';
import { KpaGridDateRangeComponent } from './components/kpa-grid-date-range-filter/kpa-grid-date-range-filter.component';
import { KpaMultiselectSearchFilter } from './components/kpa-multiselect-search-filter/kpa-multiselect-search-filter';
import { AfterFilterChangedDirective } from './directives/after-filter-changed-directive';
import { ReplacePipe } from './pipes/replace';
import { KpaStatusBadgeComponent } from './components/kpa-status-badge/kpa-status-badge.component';
import { KpaLocationDropdownFilterComponent } from './components/kpa-location-dropdown-filter/kpa-location-dropdown-filter.component';
import { KpaDateRangeFilterComponent } from './components/kpa-date-range-filter/kpa-date-range-filter.component';
import { ConfirmDiscardChangesComponent } from './components/confirm-discard-changes/confirm-discard-changes.component';
import { DynamicDialogComponent } from './components/dynamic-dialog/dynamic-dialog.component';
import { KpaLocationMultiselectFilterComponent } from './kpa-location-multiselect-filter/kpa-location-multiselect-filter.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { EmployeePickerComponent } from './components/employee-picker/employee-picker.component';

@NgModule({
	imports: [
		CommonModule,
		DateInputModule,
		FloatingLabelModule,
		DateRangeModule,
		DatePickerModule,
		ReactiveFormsModule,
		ButtonsModule,
		DropDownsModule,
		GridModule,
		DatePickerModule,
		InputsModule,
		LayoutModule,
		NotificationModule,
		FlexLayoutModule,
		DialogModule,
		RippleModule,
		LabelModule,
		TooltipModule,
		RouterModule,
		UploadModule,
		FileSelectModule,
		FormsModule,
		ListViewModule,
	],
	exports: [
		DateInputModule,
		DateRangeModule,
		DatePickerModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		ButtonsModule,
		DropDownsModule,
		GridModule,
		InputsModule,
		LayoutModule,
		LabelModule,
		TooltipModule,
		NotificationModule,
		ValidationErrorsComponent,
		ODataBindingDirective,
		KpaCardComponent,
		KpaButtonGroupComponent,
		KpaPrimaryButtonComponent,
		KpaErrorCardComponent,
		KpaSuccessCardComponent,
		KpaStatusCardComponent,
		KpaDropdownFilterComponent,
		KpaDismissMessageComponent,
		ODataBindingDirective,
		PhoneBoxComponent,
		KpaSpinnerComponent,
		KpaInsideSpinnerComponent,
		SsoLayoutComponent,
		KpaInfoCardComponent,
		FloatingDropDownlabelDirective,
		DisableAllDirective,
		FloatingDatePickerDirective,
		FloatingTimePickerDirective,
		IsEllipsisActiveDirective,
		KpaSnackbarMessageComponent,
		DropDownListFilterComponent,
		KpaMultiselectSearchFilter,
		ShowHidePasswordDirective,
		KpaShowHidePasswordDirective,
		Autosize,
		KpaTypeaheadDdlComponent,
		ShimmerLoaderComponent,
		KpaGridDirective,
		KpaPrimaryButtonDirective,
		KpaSecondaryButtonDirective,
		KpaTextButtonDirective,
		KpaDropDownButtonDirective,
		KpaButtonGroupDirective,
		KpaComboBoxDirective,
		KpaDropDownListDirective,
		KpaMultiSelectDirective,
		KpaRequiredDirective,
		KpaMultiSelectItemComponent,
		KpaThemeSampleComponent,
		KpaFileUploadComponent,
		KpaFieldMappingComponent,
		KpaGridDateRangeComponent,
		AfterFilterChangedDirective,
		ReplacePipe,
		KpaStatusBadgeComponent,
		KpaLocationDropdownFilterComponent,
		KpaDateRangeFilterComponent,
		ConfirmDiscardChangesComponent,
		KpaLocationMultiselectFilterComponent,
		EmployeePickerComponent,
	],
	declarations: [
		ValidationErrorsComponent,
		KpaButtonGroupComponent,
		KpaPrimaryButtonComponent,
		ODataBindingDirective,
		BaseComponent,
		KpaCardComponent,
		KpaErrorCardComponent,
		KpaStatusCardComponent,
		KpaDropdownFilterComponent,
		KpaDismissMessageComponent,
		PhoneBoxComponent,
		KpaSpinnerComponent,
		SsoLayoutComponent,
		KpaSuccessCardComponent,
		KpaInsideSpinnerComponent,
		KpaInfoCardComponent,
		FloatingDropDownlabelDirective,
		Autosize,
		DisableAllDirective,
		FloatingDatePickerDirective,
		FloatingTimePickerDirective,
		IsEllipsisActiveDirective,
		KpaSnackbarMessageComponent,
		DropDownListFilterComponent,
		KpaMultiselectSearchFilter,
		ShowHidePasswordDirective,
		KpaShowHidePasswordDirective,
		KpaTypeaheadDdlComponent,
		ShimmerLoaderComponent,
		KpaGridDirective,
		KpaPrimaryButtonDirective,
		KpaSecondaryButtonDirective,
		KpaTextButtonDirective,
		KpaDropDownButtonDirective,
		KpaButtonGroupDirective,
		KpaComboBoxDirective,
		KpaDropDownListDirective,
		KpaMultiSelectDirective,
		KpaRequiredDirective,
		KpaMultiSelectItemComponent,
		KpaThemeSampleComponent,
		KpaFileUploadComponent,
		KpaFieldMappingComponent,
		KpaGridDateRangeComponent,
		AfterFilterChangedDirective,
		ReplacePipe,
		KpaStatusBadgeComponent,
		KpaLocationDropdownFilterComponent,
		KpaDateRangeFilterComponent,
		ConfirmDiscardChangesComponent,
		DynamicDialogComponent,
		KpaLocationMultiselectFilterComponent,
		EmployeePickerComponent,
	],
	entryComponents: [KpaSnackbarMessageComponent, DropDownListFilterComponent, KpaMultiselectSearchFilter],
	providers: [OpenApiFormBuilder],
})
export class SharedModule {}
