import { Injectable, Inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { User } from 'oidc-client';

import { EphemeralStorage } from './ephemeralStorage';
import { LOCAL_STORAGE } from './storage';
import { AuthService } from './auth.service';
import { UserInfoDto } from '@app/core/models/user-info-dto';
import { RoleDto } from '@app/core/models/role-dto';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { SecurityService } from '@app/core/services/security.service';
import { LandingRoutes } from '@app/core/components/landing/landing-routes';
import { Platform } from '@angular/cdk/platform';
import { LogService } from './log.service';
import { ApplicationFeature, ApplicationFeatureUtil } from '../models/application-feature-enums';

/**
 * Features being managed by this service
 */
type Feature =
	| 'Employee'
	| 'Credential'
	| 'Security'
	| 'Client'
	| 'ServiceTo'
	| 'HrDrive'
	| 'MkoApplication'
	| 'ServiceToOrganization'
	| 'Notifications'
	| 'FinanceAndInsurance'
	| 'Ehs'
	| 'Incidents'
	| 'VeraTrack'
	| 'GLBA'
	| 'ASF'
	| 'FeedbackManagement'
	| 'EHSKPI'
	| 'DashboardOverview'
	| 'EHSRegulatoryData';

/**
 * Permissions from all the Features
 */
type Permissions =
	| EmployeePermissions
	| CredentialPermissions
	| SecurityPermissions
	| ClientPermissions
	| ServiceToClientPermissions
	| HrmPermissions
	| MkoPermissions
	| OrganizationPermissions
	| NotificationPermissions
	| FinanceAndInsurancePermissions
	| EhsPermissions
	| IncidentsPermissions
	| VeraTrackPermissions
	| GlbaPermissions
	| ASFPermissions
	| FeedbackManagementPermissions
	| EHSKPIPermissions
	| DashboardOverviewPermission
	| EHSRegulatoryDataPermissions;

/**
 * Permissions for the 'Employee' feature area.
 */
export enum EmployeePermissions {
	/**
	 * User can view an employee.
	 */
	ViewEmployee = 1,
	/**
	 * User can create a new employee.
	 */
	CreateEmployee = 2,
	/**
	 * User can update an existing employee.
	 */
	EditEmployee = 4,
	/**
	 * Use can view employee information
	 */
	ViewEmployeeData = 8,
	/**
	 *  User can update an existing employee
	 */
	EditEmployeeData = 16,
}

/**
 * Permissions for the 'Credential' feature area.
 */
export enum CredentialPermissions {
	/**
	 * User can manage her own role and permission assignments.
	 */
	ManageSelfCredentials = 1,
	/**
	 * User can manage the role and permission assignments of other users in her service to location access list.
	 */
	ManageOtherCredentials = 2,
}

/**
 * Permissions for the 'Security' feature area.
 */
export enum SecurityPermissions {
	/**
	 * User can log in as another user.
	 */
	Impersonate = 1,
	/**
	 * User can perform an action that affects all their locations. This gets combined with another permission to determine what you can do to all locations - administer MFA for example.
	 */
	CanActOnAllLocations = 16,
}

/**
 * Permissions for hte 'Client' feature area.
 */
export enum ClientPermissions {
	/**
	 * User can search the list of KPA clients.
	 */
	SearchClient = 1,
	/**
	 * User can view the details of a KPA client.
	 */
	ViewClient = 2,
	/**
	 * User can create a new KPA client.
	 */
	CreateClient = 4,
	/**
	 * User can update an existing KPA client.
	 */
	EditClient = 8,
}

/**
 * Permissions for the 'ServiceTo' feature area.
 */
export enum ServiceToClientPermissions {
	/**
	 * User can search the list of KPA clients' service to locations.
	 */
	SearchServiceToClient = 1,
	/**
	 * User can view details of a KPA client's service to locations.
	 */
	ViewServiceToClient = 2,
	/**
	 * User can create a new service to location for a KPA client.
	 */
	CreateServiceToClient = 4,
	/**
	 * User can update an existing service to location for a KPA client.
	 */
	EditServiceToClient = 8,
}

/**
 * Permissions for the 'HrDrive' feature area.
 */
export enum HrmPermissions {
	/**
	 * User has membership to the HR Management application.
	 */
	ApplicationMembership = 1,
}

/**
 * Permissions for the Mko application.
 */
export enum MkoPermissions {
	/**
	 * User has membership to the Mko application.
	 */
	ApplicationMembership = 1,
}

/**
 * Permissions for the 'ServiceToOrganization' feature area.
 */
export enum OrganizationPermissions {
	/**
	 * User can view details of service to location access lists for a client.
	 */
	ViewAccessList = 1,
	/**
	 * User can create a new service to location access list for a client.
	 */
	CreateAccessList = 2,
	/**
	 * User can update an existing service to location access list for a client.
	 */
	EditAccessList = 4,
}

export enum NotificationPermissions {
	/**
	 * None
	 */
	None = 0,
	/**
	 * Create NotificationServiceToEmployee
	 */
	CreateNotificationServiceToEmployee = 1,
	/**
	 * Edit NotificationServiceToEmployee
	 */
	EditNotificationServiceToEmployee = 2,
}

/**
 * The Finance and Insurance application permissions.
 */
export enum FinanceAndInsurancePermissions {
	None = 0,
	AccessFinanceAndInsurance = 1,
}

/**
 * The EHS application permissions. This is a subset of MKO.
 */
export enum EhsPermissions {
	None = 0,
	AccessEhs = 1,
}

/**
 * Incidents Permissions
 */
export enum IncidentsPermissions {
	ViewIncidents = 1,
	CreateIncidents = 2,
	EditIncidents = 4,
}

export enum VeraTrackPermissions {
	AccessVeraTrack = 1,
}

export enum GlbaPermissions {
	AccessGlba = 1,
}

export enum ASFPermissions {
	AccessASF = 1,
}

export enum FeedbackManagementPermissions {
	AccessFeedbackManagement = 1,
}

export enum EHSKPIPermissions {
	AccessEHSKPI = 1,
}

export enum DashboardOverviewPermission {
	AccessDashboardOverview = 1,
	DashboardOverviewNonEmployeeMko = 2,
	DashboardOverviewFIScheduled = 4,
	DashboardOverviewFICompletionScore = 8,
	DashboardOverviewASFCompletion = 16,
	DashboardOverviewGLBACompletion = 32,
}

export enum EHSRegulatoryDataPermissions {
	AccessEHSRegulatoryData = 1,
}
/**
 * The list of available VeraSuite roles
 */
export class VeraSuiteRoles {
	static ClientAdmin = 'Client Admin';
	static ClientEmployee = 'Client Employee';
	static KpaAdmin = 'KPA Admin';
	static KpaEmployee = 'KPA Employee';
	static KpaHrAdmin = 'KPA HR Admin';
}

/**
 * Evaluates the permissions assigned to the logged in user.
 */
@Injectable({
	providedIn: 'root',
})
export class UserInfoService extends EphemeralStorage {
	// Constants
	private static readonly _landingPageRegex = new RegExp(`${LandingRoutes.RedirectRoute}$`, 'i');

	private static readonly EmployeeIdKey: string = 'employeeId';
	private static readonly RolesKey: string = 'roles';
	private static readonly EmailKey: string = 'email';
	private static readonly PhoneKey: string = 'phone';
	private static readonly UniqueContactKey: string = 'false';
	private static readonly DisplayNameKey: string = 'displayName';
	private static readonly OptedToContinue: string = 'OptedToContinue';
	private static readonly ClientIdKey: string = 'clientId';
	private static readonly AccessListIdKey: string = 'AccessListId';
	private static readonly PrimaryLocationKey: string = 'PrimaryLocationServiceToId';
	private static readonly EhsRoleKey: string = 'EhsRole';
	private static readonly HrRoleKey: string = 'HrRole';
	private static readonly UserIdKey: string = 'UserId';
	private static readonly FirstNameKey: string = 'firstName';
	private static readonly LastNameKey: string = 'lastName';
	private static readonly ClientUniversalIdKey: string = 'clientUniversalId';

	// Member variables
	private oidcReturnUrl = '';

	/**
	 * Creates a PermissionService instance.
	 * @param storage The persistence store for API results.
	 * @param environment The environment variables for calling APIs.
	 * @param client The HTTP client.
	 */
	constructor(
		private readonly securityService: SecurityService,
		private readonly platform: Platform,
		private readonly authService: AuthService,
		private readonly log: LogService,
		@Inject(LOCAL_STORAGE) storage: Storage
	) {
		super('Kpa.UserInfo', storage);
		this.log.logVerbose('UserInfoService: constructor');
		this.createObservables();
	}

	private state$: Observable<UserInfoDto>;
	private _hasInternalRole: Observable<boolean>;

	private createObservables(): void {
		this.state$ = this.authService.user.pipe(switchMap((user) => this.handleUserChanged(user)));

		this._hasInternalRole = this.state$.pipe(
			map((_) => this.initialized && this.getItemAs<RoleDto[]>(UserInfoService.RolesKey).findIndex((role) => !role.isExternalRole) !== -1)
		);
	}

	public get userInfo() {
		return this.state$;
	}

	private handleUserChanged(user: User): Observable<UserInfoDto> {
		const sessionId = (user && user.profile && user.profile.sid) || null;
		const isLoggedIn = user && !user.expired;

		if (!isLoggedIn || !sessionId) {
			super.dispose();
			return of(<UserInfoDto>null);
		}

		const fetchData = sessionId !== this.sessionId;

		super.initialize(sessionId);

		// Put the return URL in the member variable if possible
		this.oidcReturnUrl = user.state && user.state.returnUrl ? user.state.returnUrl : '';

		return !fetchData
			? of(<UserInfoDto>null)
			: this.securityService.getMyUserInfo().pipe(
					tap((response) => {
						const features: { [key: string]: number } = {};
						if (response.permissions) {
							response.permissions.forEach((permission) => {
								// Aggregate permission bitmask for the named feature
								features[permission.featureId] = permission.permissionValue + (features[permission.featureId] || 0);
							});

							// Store aggregated feature permission bitmasks for later evaluation
							for (let key in features) {
								this.setItem(key, features[key]);
							}

							this.setItem(UserInfoService.RolesKey, response.roles);
							this.setItem(UserInfoService.EmailKey, response.primaryEmail);
							this.setItem(UserInfoService.PhoneKey, response.primaryPhone);
							this.setItem(UserInfoService.DisplayNameKey, response.employeeDisplayName);
							this.setItem(UserInfoService.FirstNameKey, response.firstName);
							this.setItem(UserInfoService.LastNameKey, response.lastName);
							this.setItem(UserInfoService.ClientUniversalIdKey, response.clientUniversalId);
							this.setItem(UserInfoService.ClientIdKey, response.clientId);
							this.setItem(UserInfoService.AccessListIdKey, response.accessListId);
							this.setItem(UserInfoService.PrimaryLocationKey, response.primaryLocationServiceToId);
							this.setItem(UserInfoService.EmployeeIdKey, response.employeeId);
							if (response.ehsRole) {
								this.setItem(UserInfoService.EhsRoleKey, JSON.stringify(response.ehsRole));
							}
							if (response.hrRole) {
								this.setItem(UserInfoService.HrRoleKey, JSON.stringify(response.hrRole));
							}

							this.setItem(UserInfoService.UniqueContactKey, 'false');
							this.setItem(UserInfoService.UserIdKey, response.userId);
						}
					})
				);
	}

	/**
	 * Validate that a user has a given permission.
	 * @param feature The feature area for the permission check.
	 * @param permission The permission value required of the user.
	 * @returns A promise to evaluate the permission inquiry after service state has been initialized.
	 */
	private has(feature: 'Employee', permission: EmployeePermissions): Observable<boolean>;
	private has(feature: 'Credential', permission: CredentialPermissions): Observable<boolean>;
	private has(feature: 'Security', permission: SecurityPermissions): Observable<boolean>;
	private has(feature: 'Client', permission: ClientPermissions): Observable<boolean>;
	private has(feature: 'ServiceTo', permission: ServiceToClientPermissions): Observable<boolean>;
	private has(feature: 'HrDrive', permission: HrmPermissions): Observable<boolean>;
	private has(feature: 'MkoApplication', permission: MkoPermissions): Observable<boolean>;
	private has(feature: 'ServiceToOrganization', permission: OrganizationPermissions): Observable<boolean>;
	private has(feature: 'Notifications', permission: NotificationPermissions): Observable<boolean>;
	private has(feature: 'FinanceAndInsurance', permission: FinanceAndInsurancePermissions): Observable<boolean>;
	private has(feature: 'Ehs', permission: EhsPermissions): Observable<boolean>;
	private has(feature: 'Incidents', permission: IncidentsPermissions): Observable<boolean>;
	private has(feature: 'VeraTrack', permission: VeraTrackPermissions): Observable<boolean>;
	private has(feature: 'GLBA', permission: GlbaPermissions): Observable<boolean>;
	private has(feature: 'ASF', permission: ASFPermissions): Observable<boolean>;
	private has(feature: 'FeedbackManagement', permission: FeedbackManagementPermissions): Observable<boolean>;
	private has(feature: 'EHSKPI', permission: EHSKPIPermissions): Observable<boolean>;
	private has(feature: 'DashboardOverview', permission: DashboardOverviewPermission): Observable<boolean>;
	private has(feature: 'EHSRegulatoryData', permission: EHSRegulatoryDataPermissions): Observable<boolean>;

	private has(feature: Feature, permission: Permissions): Observable<boolean> {
		return this.state$.pipe(
			map((_) => this.initialized && !!((this.getItemAs<number>(feature) || 0) & permission)),
			catchError((ex) => throwError(this.log.logException(ex)))
		);
	}

	/**
	 * Validate that a user has a given permission.
	 * @param feature The feature area for the permission check.
	 * @param permission The permission value required of the user.
	 * @returns A promise to evaluate the permission inquiry after service state has been initialized.
	 */
	public hasPermission(appFeature: ApplicationFeature.Employee, permission: EmployeePermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.Credentials, permission: CredentialPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.Security, permission: SecurityPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.Client, permission: ClientPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.ServiceTo, permission: ServiceToClientPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.HrDrive, permission: HrmPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.MkoApplication, permission: MkoPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.ServiceToOrganization, permission: OrganizationPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.Notifications, permission: NotificationPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.FinanceAndInsurance, permission: FinanceAndInsurancePermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.Ehs, permission: EhsPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.Incidents, permssion: IncidentsPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.VeraTrack, permssion: VeraTrackPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.GLBA, permssion: GlbaPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.ASF, permssion: ASFPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.FeedbackManagement, permssion: FeedbackManagementPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.EHSKPI, permssion: EHSKPIPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.DashboardOverview, permssion: DashboardOverviewPermission): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature.EHSRegulatoryData, permssion: EHSRegulatoryDataPermissions): Observable<boolean>;
	public hasPermission(appFeature: ApplicationFeature, permission: Permissions): Observable<boolean> {
		switch (appFeature) {
			case ApplicationFeature.Employee:
				return this.has('Employee', permission as EmployeePermissions);
			case ApplicationFeature.Credentials:
				return this.has('Credential', permission as CredentialPermissions);
			case ApplicationFeature.Security:
				return this.has('Security', permission as SecurityPermissions);
			case ApplicationFeature.Client:
				return this.has('Client', permission as ClientPermissions);
			case ApplicationFeature.ServiceTo:
				return this.has('ServiceTo', permission as ServiceToClientPermissions);
			case ApplicationFeature.HrDrive:
				return this.has('HrDrive', permission as HrmPermissions);
			case ApplicationFeature.MkoApplication:
				return this.has('MkoApplication', permission as MkoPermissions);
			case ApplicationFeature.ServiceToOrganization:
				return this.has('ServiceToOrganization', permission as OrganizationPermissions);
			case ApplicationFeature.Notifications:
				return this.has('Notifications', permission as NotificationPermissions);
			case ApplicationFeature.FinanceAndInsurance:
				return this.has('FinanceAndInsurance', permission as FinanceAndInsurancePermissions);
			case ApplicationFeature.Ehs:
				return this.has('Ehs', permission as EhsPermissions);
			case ApplicationFeature.Incidents:
				return this.has('Incidents', permission as IncidentsPermissions);
			case ApplicationFeature.VeraTrack:
				return this.has('VeraTrack', permission as VeraTrackPermissions);
			case ApplicationFeature.GLBA:
				return this.has('GLBA', permission as GlbaPermissions);
			case ApplicationFeature.ASF:
				return this.has('ASF', permission as ASFPermissions);
			case ApplicationFeature.FeedbackManagement:
				return this.has('FeedbackManagement', permission as FeedbackManagementPermissions);
			case ApplicationFeature.EHSKPI:
				return this.has('EHSKPI', permission as EHSKPIPermissions);
			case ApplicationFeature.DashboardOverview:
				return this.has('DashboardOverview', permission as DashboardOverviewPermission);
			case ApplicationFeature.EHSRegulatoryData:
				return this.has('EHSRegulatoryData', permission as EHSRegulatoryDataPermissions);
		}
	}

	public hasRole(name: string): Observable<boolean> {
		return this.state$.pipe(
			map((_) => this.initialized && this.getItemAs<RoleDto[]>(UserInfoService.RolesKey).findIndex((role) => role.roleName === name) !== -1)
		);
	}

	public get hasInternalRole(): Observable<boolean> {
		return this._hasInternalRole;
	}

	public getRoles(): Observable<RoleDto[]> {
		return this.state$.pipe(map((_) => (!this.initialized ? [] : this.getItemAs<RoleDto[]>(UserInfoService.RolesKey))));
	}

	public getDisplayName(): Observable<string> {
		return this.state$.pipe(map((_) => (!this.initialized ? undefined : this.getItem(UserInfoService.DisplayNameKey))));
	}

	// Method to determine the URL to which a User should be directed
	// after the Authentication is complete
	public getLandingPageUrl(userInfo: UserInfoDto, returnUrl: string = this.oidcReturnUrl, hasEhs: boolean = false): string {
		const mobileBrowser = this.platform.IOS || this.platform.ANDROID;

		// if mobile browser and  user has ehs and not earlier asked to continue to regular site and has ehs thenload mobile landing page
		if (mobileBrowser && !this.getUserOptedtoContinue() && hasEhs) {
			console.log('getLandingPageUrl - return ' + LandingRoutes.MobileRoute);
			return LandingRoutes.MobileRoute;
		}

		// Get the Landing Page from the UserInfo
		const roleLandingPage =
			userInfo && userInfo.roles && userInfo.roles.length
				? userInfo.roles[0].landingPage || LandingRoutes.DefaultRoute
				: LandingRoutes.DefaultRoute;

		// Make sure the return is not just the default
		returnUrl = !UserInfoService._landingPageRegex.test(returnUrl) ? returnUrl : '';

		console.log('getLandingPageUrl - return ' + (returnUrl || roleLandingPage));
		return returnUrl || roleLandingPage;
	}

	// Method to get a UserInfo from Local Storage - does not get
	// everything that is stored.
	public getUserInfoFromStorage(): UserInfoDto {
		// Create a variable to return
		let rtn: UserInfoDto = null;

		// If the Storage has been initialized, try to get a UserInfo
		if (this.initialized) {
			// Create the return
			rtn = new UserInfoDto();

			// Try to get the RoleDto
			const storedRoles: RoleDto[] = this.getItemAs<RoleDto[]>(UserInfoService.RolesKey);
			const primaryEmail: string = this.getItem(UserInfoService.EmailKey);
			const primaryPhone: string = this.getItem(UserInfoService.PhoneKey);
			const clientId: string = this.getItem(UserInfoService.ClientIdKey);
			const accessListId: string = this.getItem(UserInfoService.AccessListIdKey);
			const primLocId: string = this.getItem(UserInfoService.PrimaryLocationKey);
			const employeeId: string = this.getItem(UserInfoService.EmployeeIdKey);
			const ehsRole: string = this.getItem(UserInfoService.EhsRoleKey);
			const hrRole: string = this.getItem(UserInfoService.HrRoleKey);
			const userId: string = this.getItem(UserInfoService.UserIdKey);
			const firstName: string = this.getItem(UserInfoService.FirstNameKey);
			const lastName: string = this.getItem(UserInfoService.LastNameKey);
			const clientUniversalId: string = this.getItem(UserInfoService.ClientUniversalIdKey);

			// Add the Roles if possible
			if (storedRoles) {
				rtn.roles = storedRoles;
			}

			if (primaryEmail && primaryEmail !== 'undefined') {
				rtn.primaryEmail = primaryEmail;
			}

			if (primaryPhone && primaryPhone !== 'undefined') {
				rtn.primaryPhone = primaryPhone;
			}

			if (accessListId && accessListId !== 'undefined') {
				rtn.accessListId = accessListId;
			}

			if (primLocId && primLocId !== 'undefined') {
				rtn.primaryLocationServiceToId = primLocId;
			}

			if (clientId) {
				rtn.clientId = clientId;
			}

			if (employeeId) {
				rtn.employeeId = employeeId;
			}

			if (userId) {
				rtn.userId = userId;
			}

			if (ehsRole) {
				rtn.ehsRole = JSON.parse(ehsRole);
			}

			if (hrRole) {
				rtn.hrRole = JSON.parse(hrRole);
			}

			if (firstName) {
				rtn.firstName = firstName;
			}

			if (lastName) {
				rtn.lastName = lastName;
			}

			if (clientUniversalId) {
				rtn.clientUniversalId = clientUniversalId;
			}
		}

		// Return the result
		return rtn;
	}

	public setUserPrimaryContactInfo(primaryPhone: string, primaryEmail: string) {
		if (this.initialized) {
			this.setItem(UserInfoService.PhoneKey, primaryPhone);
			this.setItem(UserInfoService.EmailKey, primaryEmail);
		}
	}

	public setContactUnique(isUnique: string) {
		this.setItem(UserInfoService.UniqueContactKey, isUnique);
	}

	public getContactUnique(): string {
		return this.getItem(UserInfoService.UniqueContactKey);
	}

	// Feature stored
	public getUserFeatureFromStorage(): boolean {
		return this.initialized && !!((this.getItemAs<number>('MkoApplication') || 0) & MkoPermissions.ApplicationMembership);
	}
	public getUserOptedtoContinue(): boolean {
		// If the Storage has been initialized, try to get a OptedtoContinue
		if (this.initialized) {
			// Try to get the OptedToContinue
			let optedToContinue = this.getItemAs<boolean>(UserInfoService.OptedToContinue);
			if (optedToContinue) {
				return optedToContinue;
			}
		}
		return false;
	}
	public setUserOptedtoContinue() {
		// If the Storage has been initialized, try to set a OptedtoContinue
		if (this.initialized) {
			// Try to set the OptedToContinue
			this.setItem(UserInfoService.OptedToContinue, true);
		}
	}

	public reinitialize(): Observable<UserInfoDto> {
		return this.authService.user.pipe(
			switchMap((user) => {
				this.dispose();
				return this.handleUserChanged(user);
			})
		);
	}
}
